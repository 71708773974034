.TrashIcon {
  font-size: 20px;
  color: #ff0000;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .TrashIcon {
    margin: 0;
  }
}

.TrashIcon:hover {
  color: #000000;
  transition: 0.4s;
}
