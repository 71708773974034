.TopheaderContainer {
  width: 100%;
  height: 63vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ImgContainer {
  height: 100%;
  flex: 1;
}

.Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
