.theMainBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.form-container {
  background: #fff;
  border-radius: 20px;
  padding: 30px 80px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 15px 16px 0.17px rgba(0, 0, 0, 0.05);
}

h1 {
  text-align: center;
}

@font-face {
  font-family: "Avenir-Medium";
  src: url("../../assets/font/Avenir-Medium.otf");
}

.bTnProperty {
  padding: 0.5rem;
  box-sizing: border-box;
  font-family: "Avenir-Medium";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 1rem;
  background-color: #3f3c4f;
  color: #ffffff;
  border: 2px solid #3f3c4f;
  transition: background 200ms ease-in, color 200ms ease-in;
}

.bTnProperty:hover {
  background-color: #e71425;
  border: 2px solid #e71425;
  color: #ffffff;
  cursor: pointer;
  transition: border 185ms ease-in, color 200ms ease-in;
  transition: background 60ms ease-in, color 200ms ease-in;
}

.bTnProperty2 {
  padding: 0.5rem;
  box-sizing: border-box;
  font-family: "Avenir-Medium";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 1rem;
  background-color: #3f3c4f;
  color: #ffffff;
  border: 2px solid #3f3c4f;
  transition: background 200ms ease-in, color 200ms ease-in;
}

.bTnProperty2:hover {
  background-color: #e71425;
  border: 2px solid #e71425;
  color: #ffffff;
  cursor: pointer;
  transition: border 185ms ease-in, color 200ms ease-in;
  transition: background 60ms ease-in, color 200ms ease-in;
}
