@font-face {
  font-family: 'Avenir-Heavy';
  src: url('../../assets/font/Avenir-Heavy.otf');
}

.Contenitrice {
  max-width: 1200px;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 100px;
  margin-top: 30px;
  box-shadow: 0 0 5px #ccc;
}

.Wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 30px 0;
}

.ImgContainer {
  max-width: 500px;
  min-width: 290px;
  overflow: hidden;
  margin: 25px;
  transform: scale(1.15);
}

.Image {
  width: 100%;
  height: 100%;
  max-height: 400px;
  display: block;
  object-fit: cover;
}

.InfoContainer {
  max-width: 500px;
  min-width: 290px;
  margin: 25px;
}

.Row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.PriceD {
  color: crimson;
}

.Title {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.Desc p {
  margin: 20px 0px;
}

.Price {
  font-weight: 100;
  font-size: 30px;
  color: #e71425;
}

.AddContainer {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AmountContainer {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.Amount {
  width: 30px;
  height: 30px;
  border-style: ridge;
  border: 1px solid #061c8a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}

.Thumb {
  width: 100%;
  height: 100px;
  display: flex;
  cursor: pointer;
  margin: 10px 0;
}

.Thumb img {
  width: 90px;
  height: 100%;
  display: block;
  object-fit: cover;
  border: 1px solid #ddd;
  margin-right: 5px;
  opacity: 0.7;
  border-radius: 5px;
}

.Thumb img.active {
  opacity: 1;
  border: 1px solid lightseagreen;
}

.link {
  padding-left: 0px;
}

.bTnPropertyBack {
  font-family: 'Avenir-Heavy';
  margin-left: 20px;
  margin-top: 25px;
  padding: 0.5rem;
  box-sizing: border-box;
  font-weight: bold;
  letter-spacing: 0.08rem;
  margin-right: 100px;
  text-transform: uppercase;
  font-size: 1rem;
  background-color: #e71425;
  color: #ffffff;
  border: 2px solid #e71425;
  transition: background 60ms ease-in, color 60ms ease-in;
}

.bTnPropertyBack:hover {
  background-color: #ffffff;
  border: 2px solid #e71425;
  color: #e71425;
  cursor: pointer;
  transition: border 60ms ease-in, color 60ms ease-in;
  transition: background 60ms ease-in, color 60ms ease-in;
}

.bTnProperty {
  font-family: "Avenir-Heavy";
  margin-top: 15px;
  width: 50%;
  padding: 0.5rem;
  box-sizing: border-box;
  font-weight: bold;
  letter-spacing: 0.08rem;
  margin-right: 100px;
  text-transform: uppercase;
  font-size: 1rem;
  background-color: #e71425;
  color: #ffffff;
  border: 2px solid #e71425;
  transition: background 60ms ease-in, color 60ms ease-in;
}

.bTnProperty:hover {
  background-color: #ffffff;
  border: 2px solid #e71425;
  color: #e71425;
  cursor: pointer;
  transition: border 60ms ease-in, color 60ms ease-in;
  transition: background 60ms ease-in, color 60ms ease-in;
}
