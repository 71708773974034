/* .Container {
  width: 100%;
  height: 63vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ImgContainer {
  height: 100%;
  flex: 1;
}

.Image {
  height: 100%;
  width: 100%;
  object-fit: contain;
} */

.Container {
  /* max-width: 1200px; */
  display: flex;
  align-items: center;
  -webkit-justify-content: center;
  /* Safari */
  justify-content: center;
}

.ImgContainer {
  padding: 0px;
}

.Image {
  width: 100%;
  height: auto;
  display: block;
}