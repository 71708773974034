a.link {
  text-decoration: none;
  color: #000000d4;
  font-size: 13px;
  transition: all 0.3s;
  display: block;
  margin-bottom: 20px;
}
a.link:hover {
  color: #e71425;
}

.diBox {
  background-color: #e71425;
  color: #fff;
}

/*.daLink {
  display: none;
}*/

.btn {
  color: #fff;
}

.hamburguer {
  /* font-size: 40px; */
  padding-right: 5px;
  font-weight: 700;
  color: #fff;
}

.title {
  font-size: 20px;
  color: #fff;
}

/*MEDIAQUERIES*/

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1048px) {
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 898px) {
  /*  .daLink {
    display: list-item;
    font-size: 12.5px;
    justify-content: left;
    padding-left: 18px;
    text-decoration: none;
    color: #000000;
  }*/
}

@media only screen and (max-width: 882px) {
  .title {
    font-size: 16px;
    color: #fff;
  }
}

@media only screen and (max-width: 645px) {
  .diBox {
    background-color: #e71425;
    color: #fff;
  }

  .btn {
    color: #fff;
  }

  .hamburguer {
    font-size: 40px;
    padding-right: 5px;
    font-weight: 700;
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
}

.menu {
  margin-top: 20px;
}

.menuList {
  margin: 0;
  list-style: none;
  padding: 8px 16px;
  padding-bottom: 0;
}

a.menuLink {
  text-decoration: none;
  color: #000000d4;
  font-size: 16px;
  transition: all 0.3s;
  display: inline-block;
  margin-bottom: 10px;
}
a.menuLink:hover {
  color: #e71425;
}

@media only screen and (min-width: 646px) {
  .menu {
    display: none;
  }
}
