.logoFooter {
  width: 150px;
  height: 150px;
}

.socialMedia {
  font-size: 40px;
  color: #ffffff;
}

.socialMedia:hover {
  color: #fe0000;
  transition: 0.4s;
}
.category_list {
  list-style: none;
  padding: 0;
}
.paraGraph {
  font-size: 13.5px;
}
.category_list li {
  margin-top: 8x;
  margin-bottom: 5px;
  font-size: 14px;
}
.category_list li a {
  transition: all 0.4s;
}
.category_list li a:hover {
  color: #e71425;
}

.category_list a {
  color: white;
  text-decoration: none;
}

.hover-red:hover {
  color: #e71425;
}
.contactDiv p {
  font-size: 14px;
  transition: all 0.4s;
}

.socialIcons li a i {
  font-size: 25px;
}
/*MEDIAQUERIES*/

@media only screen and (max-width: 1920px) {
  footer {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 1048px) {
  footer {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 960px) {
  footer {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 882px) {
  .title {
    font-size: 16px;
    color: #fff;
  }
  footer {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 645px) {
  a.link {
    text-decoration: none;
    color: #000000;
    padding-left: 15px;
  }

  .diBox {
    background-color: #e71425;
    color: #fff;
  }

  .btn {
    color: #fff;
  }

  .hamburguer {
    font-size: 40px;
    padding-right: 5px;
    font-weight: 700;
    color: #fff;
  }
  footer {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 640px) {
  footer {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .contenitrice {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 405px) {
  footer {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .contenitrice {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media screen and (min-width: 576px) {
  .footer-categories {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1440px) {
  .footer-categories {
    gap: 30px;
  }
}