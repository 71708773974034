.TopheaderContainer {
  width: 100%;
  height: 63vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ImgContainer {
  height: 100%;
  flex: 1;
}

.Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Wrapper {
  width: 100%;
  font-size: 25px;
  top: 50%;
  text-align: center;
  color: #000000;
  padding-top: 60px;
  padding-bottom: 50px;
}

.Wrapper p {
  font-size: 20px;
}
