.wrapper {
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
}
.card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #c9c9c9;
}
.imageWrapper {
  width: 100%;
  height: 250px;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.content {
  padding: 25px;
}
.title {
  color: #08001e;
  font-size: 25px;
}
.text {
  color: #000000bf;
  font-size: 15px;
}

.time {
  margin-top: 25px;
}

.mapIcon {
  color: #ff0000;
}
