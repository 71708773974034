/* .Container {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: blue;
  position: relative;
} */

.Arrow {
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
  cursor: pointer;
}

.TopheaderContainer {
  width: 100%;
  height: 63vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ImgContainer {
  height: 100%;
  flex: 1;
}

.Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Wrapper {
  width: 100%;
  font-size: 25px;
  top: 50%;
  text-align: center;
  color: #000000;
  padding-top: 60px;
  padding-bottom: 50px;
}

.Wrapper p {
  font-size: 20px;
}