.header {
  width: 100%;
  /* height: 3rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e71425;
  padding: 10 26.5px;
  /* position: sticky;*/
  top: 45px;
  /*top: 45px;*/
}

.header ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.header li {
  /* padding-top: 15px; */
}

.header a {
  text-decoration: none;
  font-size: 18.5px;
  color: #ffffff;
}

.header a:hover,
.header a:active,
.header a.active {
  color: white;
}

.badge {
  background-color: #98cc20;
  color: white;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}

/*MEDIAQUERIES*/

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1048px) {
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 882px) {
  .header a {
    text-decoration: none;
    font-size: 15px;
    color: #ffffff;
  }
}

@media only screen and (max-width: 645px) {
  .header {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e71425;
    padding: 10 26.5px;
    /* position: sticky;*/
    top: 45px;
    /*top: 45px;*/
  }

  .header ul {
    list-style: none;
    margin-left: -110px;
    padding: 0;
    display: flex;
    align-items: baseline;
  }

  .header li {
    padding-top: 15px;
    padding-left: 125px;
  }

  .header a {
    text-decoration: none;
    font-size: 15px;
    color: #ffffff;
    display: none;
  }

  .header a:hover,
  .header a:active,
  .header a.active {
    color: white;
  }

  .badge {
    background-color: #98cc20;
    color: white;
    border-radius: 12px;
    padding: 0 1rem;
    margin-left: 0.5rem;
  }
}

@media only screen and (max-width: 640px) {
}
